<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {computed} from "vue";
import {useStore} from "vuex";
import SvgIcon from '@/components/SvgIcon';
import PercentGraph from '@/components/PercentGraph';
import EmptyGraph from '@/components/EmptyGraph';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country', 'items', 'indices'],
  setup(props){
    const store = useStore();
    const values = store.getters.getDataForObjectiveGraphs(props.indices, props.items, 3)
    const textIndi = computed( () => store.getters.getIndicatorText(3,3,props.country.id));
    const all    = store.state.indexGuide.filter(d => d.axis == 3 && d.objective == 3);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      getIcon : store.getters.getIcon,
      values,
      textIndi,
      all
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    SvgIcon,
    PercentGraph,
    EmptyGraph
  }
}
</script>


<template>
  <div class="row">
    <div class="col-12 pt-4 mt-4">
        <h2><strong>OBJETIVO 3</strong></h2>
        <div class="table">
        <table class="table objetivos">
          <tbody>
            <tr>
              <td rowspan="2">
                <div class="row mb-4">
                  <div class="col-2">
                    <svg-icon :icon="getIcon('gasto')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-10">
                    90% de los jóvenes están facultados con las habilidades, el conocimiento y la capacidad de protegerse del VIH
                  </div>
                </div>
              </td>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('personas')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Compromiso 5 de la Reunión de Alto Nivel (High Level Meeting) 2016-2021 de la ONU</strong><br>
                  Garantizar que el 90 % de los jóvenes tiene las habilidades,
los conocimientos y la capacidad de protegerse del VIH y puede acceder a los sistemas de salud sexual y reproductiva antes de 2020 a fin de reducir el número de nuevos casos de infección por VIH en adolescentes y mujeres jóvenes a menos de 100 000 al año
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('tratamiento')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Objetivo de Desarrollo Sostenible  3</strong><br>
                  Buena salud y bienestar
                  </div>
                  <div class="col-1">
                    <svg-icon :icon="getIcon('tratamiento')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Objetivo de Desarrollo Sostenible  10</strong><br>
                  Reducción de las desigualdades.
                  </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
      <div class="col-sm-10 offset-sm-1 mb-4 pb-4">        
      <div v-html="textIndi"></div>
    </div>
    <div v-for="index of all" :key="`tmp-${index.db_name}`" class="col-12">
        <percent-graph v-if="values.find(d => index.db_name == d.column)" :indice="values.find(d => index.db_name == d.column)" :country="country" />
        <empty-graph v-else :indice="index" :country="country" />
      </div>
  </div>
</template>